.deadline {
    &-info {
        //height: 28px;
        margin-top: 6px;
        margin-bottom: 6px;
        //width: 95%;
        text-align: left;
        padding: 6px 8px 8px;
        border-radius: 6px;
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 13px;
        transition: color 0.3s linear;
        //height: 86%;
        //width: 95%;
        &.utmn {
            border: 2px solid #F46386;
            background: #F46386;
             &:hover {
                cursor: pointer;
                color: #F46386;
                background-color: #fff;
                border: 2px solid #F46386;
            }
        }
        &.netology {
            border: 2px solid #3492c5;
            background: #3492c5;
             &:hover {
                cursor: pointer;
                color: #3492c5;
                background-color: #fff;
                border: 2px solid #3492c5;
            }
        }
    }
    &-info-on {
        height: 28px;
        margin-top: 4px;
        width: 95%;
        text-align: left;
        padding: 5px 11px;
        color: #F46386;
        background-color: #fff;
        border: 2px solid #F46386;
        border-radius: 6px;
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        font-size: 13px;
        transition: color 0.3s linear;
        &:hover {
            cursor: pointer;
            color: #fff;
            background-color: #F46386;
            border: none;
        }
    }
}

.shedule-table {
    width: 100%;
    margin-top: 36px;
    border-spacing: 1px;
}

.days {
    padding: 5px;
    font-family: "Unbounded", sans-serif;
    font-weight: 500;
    font-size: 13px;
    border: 1px;

    &-row {
      border-bottom: 1px dotted #adadad;
    }

    &.prev {
        color: #adadad;
    }
    &.now {
      color: #7b61ff;
    }
    &.now-clmn {
        border-left: 2px solid #7b61ff;
      }
    &.next {
        color: #333333;
    }
}

.vertical-deadline {
    vertical-align: top;
    text-align: center;
    height: 72px;
    width: 195px;
    padding: 1px 4px;
    border-bottom: 1px dotted #adadad;
    &-heading {
        padding-left: 47px;
        padding-top: 7px;
        padding-bottom: 7px;
        font-family: "Roboto Mono", monospace;
        font-weight: 400;
        font-size: 12px;
        width: 0;
        border-bottom: 1px dotted #adadad;
        &::first-line {
            font-family: "Roboto", sans-serif;
            font-weight: 700;
            font-size: 13px;
        }
    }
}

.vertical   {
    height: 80px;
    width: 195px;
    padding: 1px 4px;
    border-bottom: 1px dotted #adadad;
    text-align: center;
    vertical-align: middle;
    &-heading {
        text-align: right;
        vertical-align: top;
        padding-left: 47px;
        padding-top: 7px;
        padding-bottom: 7px;
        font-family: "Roboto Mono", monospace;
        font-weight: 400;
        font-size: 12px;
        width: 0;
        border-bottom: 1px dotted #adadad;
        &::first-line {
            font-family: "Roboto", sans-serif;
            font-weight: 700;
            font-size: 13px;
        }
    }
}

.off-deadline {
    margin-top: 7px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: #7b61ff;
    background: none;
    padding: 4px 8px;
    border-radius: 6px;
    border: 1px solid #7b61ff;
    transition: color 0.3s linear;
    &:hover {
        cursor: pointer;
        background-color: #5856d6;
        color: #fff;
    }
}

.past-lesson {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 12px;
    text-align: left;
    width: 95%;
    background: #ECEDF0;
    border: none;
    border-radius: 6px;
    &:hover {
        cursor: pointer;
    }
}

.company-name {
    vertical-align: top;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 10px;
    color: #7B61FF;
    margin-bottom: 4px;
}

.lesson-name {
    vertical-align: top;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: #2C2D2E;
}

.netology-lesson {
    margin-top: 6px;
    margin-bottom: 6px;
    text-align: left;
    padding: 6px 8px 8px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 13px;

    //display: flex;
    //flex-direction: column;
    //justify-content: space-evenly;
    padding-left: 10px;
    text-align: left;
    height: 84%;
    background: #00A8A833;
    border: none;
    border-radius: 6px;
    &:hover {
        cursor: pointer;
    }
}

.TyumGU-lesson {
    margin-top: 6px;
    margin-bottom: 6px;
    text-align: left;
    padding: 6px 8px 8px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 13px;

    //display: flex;
    //flex-direction: column;
    //justify-content: space-evenly;
    height: 84%;
    background: #7B61FF33;
    border: none;
    border-radius: 6px;
    &:hover {
        cursor: pointer;
    }
}

.past {
    background: #ECEDF0;
}

.vertical-line {
    margin-top: 17px;
    margin-left: 365px;
    position: absolute;
    border-left: 2px solid #7B61FF;
    height: 590px;
}
