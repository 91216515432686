@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Unbounded:wght@200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
// сброс дефолтных стилей
@import 'scss-reset/reset';

// app file start ========
.App {
    text-align: center;
    &-logo {
        height: 40vmin;
        pointer-events: none;
    }
    &-header {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
    }
    &-link {
        color: #61dafb;
    }
}
//TODO: ругается здесь
//media (prefers-reduced-motion: no-preference) {
//    animation: App-logo-spin infinite 20s linear;
//}
//keyframes App-logo-spin {
//    transform: rotate(0deg);
//}
//to {
//    transform: rotate(360deg);
//}
// app file end ========



html {
    height: 100%;
}

body {
    height: 100%;
    background: #fff;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.error-message {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    color: red;
    margin-top: 10px;
}
// loader
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
