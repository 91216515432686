input:focus-within {
  border-color: #7b61ff;
  }


.wrapper {
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 20px;
}

.header {
  max-width: 100%;
  margin-left: 10%;
  margin-right: 3%;

  &-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
  }
}

.rectangle {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: stretch;

    &-info {
      display: block;
      padding: 6px 12px 6px 12px;
      //margin: 22px 120px;
      margin-right: 5px;
      width: 648px;
      min-height: 106px;
      border-radius: 6px;
      text-decoration: none;

      &.task, &.test, &.quiz {
        background: #3492c573;
        border: 1px solid #3492c5;

        .source {
          color: #f46386;
        }
      }
      &.utmn {
        background: #f4638633;
        border: 1px solid #f46386;

        .source {
          color: #f46386;
        }
      }

      &.modeus {
        background: #7B61FF33;
        border: 1px solid #7B61FF;

        .source {
          color: #7B61FF;
        }
      }
      &.netology {
        background: #00A8A833;
        border: 1px solid #00A8A8;

        .source {
          color: #00A8A8;
        }
      }
    }
  .source {
    margin-top: 6px;
    //color: #f46386;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 12px;

    &-first-word {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 12px;
    }
  }
}


a {
  text-decoration: none;
}

.shedule-export {
  display: flex;
  align-items: center;
}



.shedule {
  font-family: "Unbounded", sans-serif;
  font-weight: 500;
  font-size: 32px;
  text-decoration: none;
}

.export-btn {
  cursor: pointer;
  margin-left: 18px;
  height: 28px;
  background-color: #7b61ff;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 6px;
  padding: 4px 8px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 13px;
  font-style: normal;
  transition: color 0.2s linear;

  &:hover {
    background-color: #4745b5;
  }
}

.cache-btn {
  margin-left: 18px;
  height: 28px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #7b61ff;
  background: none;
  padding: 4px 8px;
  border-radius: 6px;
  border: 1px solid #7b61ff;
  transition: color 0.3s linear;

  &.updated {
    background-color: #4caf50;
    color: white;
  }

  &:hover {
    cursor: pointer;
    background-color: #5856d6;
    color: #fff;
  }
}

.exit-btn {
  background: none;
  cursor: pointer;
  border: none;
  color: #333333;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 13px;
  &-cross{
    margin-left: 7px;
  }
}

.date-event {
  float: right;
}

.name-event {
  &-text {
    color: #2c2d2e;
    font-family: "Unbounded", sans-serif;
    font-weight: 700;
    font-size: 13px;
    margin-top: 10px;
  }
}

a { 
  text-decoration: none; 
}


.task-event {
  padding-left: 2px;
  margin-bottom: 0px;
  &-text {
    color: #2c2d2e;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 12px;
  }
}

.input-name {
  margin-top: 8px;
  width: 320px;
  height: 32px;
  border-radius: 4px;
  border-color: #d9d9d9;
}

.input-email {
  display: block;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d9d9d9;
  margin-top: 16px;
  padding: 8px;
  width: 320px;
  height: 32px;
  border-radius: 4px;
}

.login-btn-log {
  width: 100px;
  position: relative;
  cursor: pointer;
  border: none;
  margin: 24px auto;
  background-color: #7b61ff;
  color: #fff;
  text-decoration: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  transition: color 0.2s linear;


  &:hover {
    background-color: #4745b5;
  }
}



.login-container {
  display: flex;
  align-items: center;
  margin-left: 70px;
  flex-direction: column;
  margin-top: 60px;
}

.shedule-login {
  font-family: "Unbounded", sans-serif;
  font-weight: 500;
  font-size: 30px;
}

.login-select {
display: "flex"; 
flex-direction: "column"; 
margin-top: 16px;
margin-bottom: -20px;

  &:focus { 
    border-color: #7b61ff; 
  }
}

.login-netologiya,
.login-fio {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 15px;
  max-width: 320px;
  width: 100%;
  margin: 24px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: column;
  text-align: left;
}

//.calendar-date {
//  position: absolute;
//  top: 165px;
//  right: 248px;
//  background-color: #ecedf0;
//  width: 406px;
//  height: 32px;
//  border-radius: 6px;
//  border: 1px solid rgba(217, 217, 217, 0.5);
//  font-family: "Unbounded", sans-serif;
//  font-weight: 500;
//  font-size: 15px;
//  padding-left: 14px;
//  transition: color 0.2s linear;
//
//  &:hover {
//    background-color: #e7e7ea;
//  }
//}

.persona_container {
  display: flex; 
  align-items: center; 
  margin-top: 10px;
  margin-bottom: 0px;
}

.avatar_path {
  width: 40px; 
  height: 40px; 
  overflow: hidden; 
  display: flex; 
  justify-content: center;
  align-items: center;
  border-radius: 50%; 
  margin-right: 10px; 
}

.avatar_path img {
  height: auto; 
  width: 100%; 
  object-fit: cover; 
}

// src/components/ErrorMessage.scss

.error-message {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    padding: 15px;
    border-radius: 5px;
    margin: 20px 0;
    font-size: 16px;
    width: 100%;
}
